@import url(https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  background-color: yellowgreen;
  color: darkgreen;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: olivedrab;
}

a {
  color: seagreen;
}

.Bong-ripper {
  max-width: 60vw;
}

.ByTheWay {
  max-width: 96%;
}

.Card {
  font-family: 'Bree Serif', serif;
  background-color: yellowgreen;
  margin: 10px;
  margin-bottom: 6vh;
  margin-top: 6vh;
  padding: 10px;
  border-width: 2px;
  border-radius: 12px;
  border-style: dashed;
  border-color: olivedrab;
}

.Card-header {
  font-family: 'Fredoka One', cursive;
  background-color: darkgreen;
  color: yellowgreen;
  padding: 10px;
  border-radius: 12px;
  font-size: calc(25px + 2vmin);
  margin: 5px;
}

.ContactLinks {
  padding: 8px;
  font-family: 'Fredoka One', cursive;
}

.Header {
  font-family: 'Fredoka One', cursive;
  background-color: darkgreen;
  color: yellowgreen;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(25px + 2vmin);
  min-width: 98vw;
  min-height: 100vh;
}

.Heres {
  margin-bottom: 20vh;
  font-size: calc(25px + 2vmin);
}

.ImageContainer {
  min-width: 100%;
  height: 130vh;
  background-size: 175%;
  background-color: yellowgreen;
  overflow: hidden;
}

.ImgFile {
  min-width: 100%;
  background-position-x: center;
}

.Intro {
  font-family: 'Pacifico', cursive;
  background-color: gold;
  background-image: linear-gradient(yellowgreen 5%, gold);
  padding: 2em;
  padding-top: 25vh;
  min-height: 75vh;
  font-size: calc(35px + 2vmin);
}

.Informo {
  margin-top: 1em;
  font-family: 'Bree Serif', serif;
  margin-left: 5vw;
  margin-right: 5vw;
}

.LinksContainer {
  display: flex;
  flex-direction: column;
}

.NoDecorationLink {
  text-decoration: none;
  color: darkgreen;
}

.Pacifico {
  font-family: 'Pacifico', cursive;
}

.Title {
  font-size: calc(50px + 2vmin);
}
